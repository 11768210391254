<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Past Members</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Members"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="members"
        no-data-text="No Past Members found"
      >
        <template v-slot:item.full_name="{ item }">
          <router-link
            :to="{
              name: 'module-flightmode-members-profile',
              params: { memberId: item.id, isCurrent: false },
            }"
          >
            {{ item.full_name }}
          </router-link>
        </template>
        <template v-slot:item.expiry="{ item }">
          {{
            item.memberships.length > 0
              ? item.memberships[0].formatted_dates.end_date
              : "N/A"
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                :to="{
                  name: 'module-flightmode-members-profile',
                  params: { memberId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Members",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-members",
          },
        },
        {
          text: "Past Members",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Expiry", value: "expiry" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    members() {
      let members = this.$store.state.flightmode.members["past_members"];
      const searchTerm = this.searchTerm.toLowerCase();

      if (this.searchTerm !== "") {
        members = members.filter((m) => {
          const name = m.full_name.toLowerCase();
          const email = m.email.toLowerCase();
          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return members;
    },
  },
};
</script>
