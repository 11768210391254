var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Past Members")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Archived Members","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.members,"no-data-text":"No Past Members found"},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-flightmode-members-profile',
            params: { memberId: item.id, isCurrent: false },
          }}},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"item.expiry",fn:function({ item }){return [_vm._v(" "+_vm._s(item.memberships.length > 0 ? item.memberships[0].formatted_dates.end_date : "N/A")+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-flightmode-members-profile',
                params: { memberId: item.id },
              }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }